import React, { useState, useEffect, Component } from 'react';
import axios from 'axios';
// import Slider from './slider'
import { Link } from 'react-router-dom';
const Product = () => {
  const [products, setProducts] = useState([]);

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    // Fetch products from the server
    axios.get(`${baseURL}/`)

    .then(response => {
        setProducts(response.data);
      })
      .catch(error => {
        console.error('Error fetching products:', error);
      });
  }, [baseURL]);

  return (
    <React.Fragment>
      {/* <Slider /> */}
     
      <div className='row' className="text-div-main">
        <p className='text-p1'>
        Welcome to Vareprint, where personalisation meets perfection!</p>

<p className='text-p'> It's Simple, Choose a product - select a template - upload your image - give us your text - we will prepare your artwork & send it to you for  <br /> approval before we proceed to print.
        </p>

      </div>
    <div className='container product-container'>
      <p className='explore_title'>Explore All Categories</p>
      <div className='row'>
      {products.map(product => {
        let productDescription;
        if (product.pid === 10) {
          productDescription = '';
        } else {
          productDescription = 'Personalise by adding one or multiple pictures with the text of your choice.';
        }

        return (
          <div key={product.pid} className='col-sm-4' id='product-div'>
            <Link to={`/design/${product.pid}`}>
            <img src={`${baseURL}/images/${product.image}`} alt={product.pname} className='product_image' />
              <p className='product_name'>{product.pname}</p>
              </Link>
              <p className='text_for_product'>{productDescription}</p>
           
          </div>
        );
      })}
    </div>
    </div>
    </React.Fragment>
  );
};
 

export default Product;
