import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
// import './Footer.css'; // Ensure you create and import the CSS file

const Footer = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const [deliverydata, setdeliverydata] = useState({
    city: '',
    state: '',
    country: ''
  });
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');



  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleShow1 = () => setShowModal1(true);
  const handleClose1 = () => setShowModal1(false);

  const handlePostcodeSubmit = async (e) => {
    e.preventDefault();
    const postcode = e.target.postcode.value;

    try {
      // Make the API request
      const response = await axios.get(`${baseURL}/check-delivery-status/${postcode}`);
      
      // Assuming response.data is directly the delivery data
      const delivery_data = response.data;

      // Update the state with the delivery data
      setdeliverydata({
        city: delivery_data.city || '',
        state: delivery_data.state || '',
        country: delivery_data.country || ''
      });
      setErrorMessage(''); // Clear any previous error messages
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setErrorMessage('Delivery not available');
      } else {
        console.error("Error fetching delivery data:", error);
        setErrorMessage('An error occurred while fetching delivery data');
      }
    }
  };

  // State for each collapsible section
  const [isExpanded, setIsExpanded] = useState({
    product: false,
    companyInfo: false,
    personalInfo: false,
    contact: false
  });

  const toggleCollapse = (section) => {
    setIsExpanded(prevState => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };

  return (
    <React.Fragment>
      <div className='footer_container'>
        <div className='container-fluid footer_container'>
          <div className='row footer_row'>
            <div className='for-mobile'>
              <p className='heading' onClick={() => toggleCollapse('product')}>Product &nbsp;<span className='icon-p'><i class="fa fa-plus-circle" aria-hidden="true"></i></span>
              </p>
              <div className={`collapsible ${isExpanded.product ? 'expanded' : ''}`}>
                <ul className='footer_for'>
                  <Link to='/design/1'><li className='footer1'>Acrylic Desktops</li></Link>
                  <Link to='/design/2'><li className='footer1'>Canvas Prints</li></Link>
                  <Link to='/design/3'><li className='footer1'>Wooden Desktops</li></Link>
                  <Link to='/design/10'><li className='footer1'>T-shirts</li></Link>
                  <Link to='/design/5'><li className='footer1'>Notebooks</li></Link>
                </ul>
              </div>
            </div>
            <div className='for-mobile1'>
              <p>&nbsp;</p>
              <ul className='footer_for'>
                <Link to='/design/7'><li className='footer1'>Coasters</li></Link>
                <Link to='/design/8'><li className='footer1'>Tablemats</li></Link>
                <Link to='/design/9'><li className='footer1'>Fridge Whiteboards</li></Link>
                <Link to='/design/24'><li className='footer1'>Framed Prints</li></Link>
              </ul>
            </div>
            <div className='for-mobile'>
              <p className='heading' onClick={() => toggleCollapse('companyInfo')}>Company Info &nbsp;<span className='icon-p'><i class="fa fa-plus-circle" aria-hidden="true"></i></span></p>
              <div className={`collapsible ${isExpanded.companyInfo ? 'expanded' : ''}`}>
                <ul className='footer_for'>
                  <Link to='/'><li className='footer1'>Home</li></Link>
                  <Link to='/about-us'><li className='footer1'>About Us</li></Link>
                  <Link to='/privacy-policy'><li className='footer1'>Privacy Policy</li></Link>
                  <Link to='/terms-condition'><li className='footer1'>Terms & Conditions</li></Link>
                  <Link to='/contact-us'><li className='footer1'>Contact</li></Link>
                </ul>
              </div>
            </div>
            <div className='for-mobile'>
              <p className='heading' onClick={() => toggleCollapse('personalInfo')}>Personal Info &nbsp;<span className='icon-p'><i class="fa fa-plus-circle" aria-hidden="true"></i></span></p>
              <div className={`collapsible ${isExpanded.personalInfo ? 'expanded' : ''}`}>
                <ul className='footer_for'>
                <Link to='/profile'> <li className='footer1'>My Account</li></Link>
                  <Link to='/profile'><li className='footer1'>Order History</li></Link>
                  <Link to='https://bluedart.com/tracking' target='_blank'><li className='footer1'>Order Tracking</li></Link>
                  <li className='footer1' onClick={handleShow}>Serviceable Pincodes</li>
                  <li className='footer1' onClick={handleShow1}>Experience Center</li>
                </ul>
              </div> 
            </div>
            <div className='for-mobile'>
              <p className='heading' onClick={() => toggleCollapse('contact')}>Contact &nbsp;<span className='icon-p'><i class="fa fa-plus-circle" aria-hidden="true"></i></span></p>
              <div className={`collapsible ${isExpanded.contact ? 'expanded' : ''}`}>
                <ul className='footer_for'>
                  <li>B-412, 4th Floor, Durian Estate</li>
                  <li>Goregaon-Mulund Link Road</li>
                  <li>Goregaon (East)</li>
                  <li>Mumbai - 400063, India.</li>
                  <li>support@vareprint.com</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row foot-row'>
          <div className='col-sm-4 first_footer_div'>
            <div className='row footer2-row'>
              <div className='col-sm-6'>
                <p className="connect_wu">Connect with us :</p>
              </div>
              <div className='col-sm-6'>
                <Link to={`https://www.facebook.com/Vareprint?mibextid=ZbWKwL`}><img src={`${baseURL}/images/Facebook.png`} alt='vareprint' className='social_img' /></Link>
                <Link to={`https://www.instagram.com/vareprint?igsh=ZTZzMTUyMnlqeGEy`}><img src={`${baseURL}/images/Instagram.png`} alt='vareprint' className='social_img' /></Link>
                <Link to={`https://youtube.com/@vareprint-gifts?si=Om6Pn3sN27IZV9SF`} target='blank'><img src={`${baseURL}/images/youtube.svg`} alt='vareprint' className='social_img youtube-social-img' /></Link>
              </div>
            </div>
          </div>
          <div className='col-sm-4'>
            <p className='connect_wu'>© Vareprint 2021. All rights reserved.</p>
          </div>
          <div className='col-sm-4 payment-icon'>
            <img src={`${baseURL}/images/visa.png`} alt='vareprint' className='social_img2' />
            <img src={`${baseURL}/images/mastercard.png`} alt='vareprint' className='social_img2' />
            <img src={`${baseURL}/images/americanexpress.png`} alt='vareprint' className='social_img2' />
            <img src={`${baseURL}/images/gpaylogo.png`} alt='vareprint' className='social_img2' />
            <img src={`${baseURL}/images/paytm.png`} alt='vareprint' className='social_img2' />
          </div>
        </div>
        <div>
        <Link to={`https://api.whatsapp.com/message/O63DVQF7INFCO1?autoload=1&app_absent=0`} target='blank'>
        <img src={`${baseURL}/images/whatsapp.svg`} alt='vareprint' className='whatsapp-icon' />
        </Link>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose}>
  <Modal.Header closeButton>
    <Modal.Title>Check Serviceability</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form onSubmit={handlePostcodeSubmit}>
      <Form.Group className="mb-3" controlId="formBasicPostcode">
        <Form.Label>Enter your Postcode:</Form.Label>
        <Form.Control type="text" placeholder="Enter postcode" name="postcode" />
      </Form.Group>
      <div className='submit-check-btn-div'>
      <Button className='postcode-btn' type="submit">
        Check
      </Button>
      </div>
    </Form>
    <div className='delivery-data-output'>
    {errorMessage ? (
      // Show the error message if there is one
      <div className="alert alert-danger mt-3">{errorMessage}</div>
    ) : deliverydata.city || deliverydata.state || deliverydata.country ? (
      // Show the delivery data if available and there is no error
      <div>
        <h3 className='avalibale-location'>Delivery Available on this location:</h3>
        <p className='delivery-data'>City: {deliverydata.city}</p>
        <p className='delivery-data'>State: {deliverydata.state}</p>
        <p className='delivery-data'>Country: {deliverydata.country}</p>
      </div>
    ) : (
      // Optionally, show a placeholder or nothing if no data and no error
      <div></div>
    )}
    </div>
  </Modal.Body>
 
</Modal>



      <Modal show={showModal1} onHide={handleClose1}>
       
        <Modal.Body>
         <div>
          <p className='f-text1'>Vareprint PVT LTD</p>
          <p className='f-text2'>B-412, 4th Floor, Durian Estate<br />
Goregaon-Mulund Link Road <br />
Goregaon (East) <br />
Mumbai - 400063, India. <br />
support@vareprint.com</p>
         </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default Footer;
