import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Sidebar from './sidebar';
import Navbar from './navbar';
import Footer from './footer';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const Design = () => {
  const [products, setProducts] = useState([]); // State for storing products
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  useEffect(() => {
    // Fetch all products
    axios.get(`${baseURL}/`) // Update endpoint to match your API
      .then(response => {
        setProducts(response.data || []); // Default to empty array if response.data is undefined
      })
      .catch(error => {
        console.error('Error fetching products:', error);
      });
  }, []);

  // Search and filter products
  const filteredProducts = products.filter(product =>
    (product.pname?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
    (product.description?.toLowerCase() || '').includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleDelete = (productId) => {
    // Delete product by ID
    axios.delete(`${baseURL}/delete-product/${productId}`)
      .then(response => {
        // Update the state to remove the deleted product
        setProducts(products.filter(product => product.pid !== productId));
        // Optionally reset pagination
        if (currentPage > Math.ceil((products.length - 1) / itemsPerPage)) {
          setCurrentPage(currentPage - 1);
        }
      })
      .catch(error => {
        console.error('Error deleting product:', error);
      });
  };

  return (
    <div className="layout">
      <Sidebar />
      <div className="main-content">
        <Navbar />
        <div className="content">
          <div className='container'>
            <h2>Products</h2>
            <input
              type="text"
              placeholder="Search by Name or Description..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input-main"
            />
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length > 0 ? (
                  currentItems.map(product => (
                    <tr key={product.pid}>
                      <td>
                        <img
                          src={`${baseURL}/images/${product.image}`}
                          alt={product.pname}
                          style={{ height: '50px', objectFit: 'cover' }}
                        />
                      </td>
                      <td>{product.pname || 'No Name'}</td>
                      <td>{product.description || 'No Description'}</td>
                      <td>
                        <div className='d-flex justify-content-between'>
                          <Link to={`/edit-product/${product.pid}`} className='btn btn-primary btn-sm'>
                            Edit
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div>
                          <button
                            onClick={() => handleDelete(product.pid)}
                            className='btn btn-danger btn-sm'
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No products found</td>
                  </tr>
                )}
              </tbody>
            </table>
            
            <div className="pagination">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>Page {currentPage} of {totalPages}</span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Design;
