import React from 'react';
import Nav from './nav';
import Footer from './footer';


const about = () => {



  return (
    <React.Fragment>
      <Nav />
      <div className='container'>
        <div className='row'>
            <h3 className='about-us-title'>About Us</h3>
            <p>At Vareprint (pronounced - We Are Print), you, our customer, is at the centre of everything we do. Our team consists of print professionals who have over 20 years of experience in the print market and we make it our business to deliver great quality custom printed materials to you every single time. Our passion for printing and the pride that we take in our work is known to be contagious and we take every measure to ensure that your journey with us is seamless and delightful.<br /><br />

The objective of our website is to address the gap in the market for an unbeatable combination of affordable exclusive designs & great print quality.<br /><br />

We use state-of-the-art & multiple types of printing technologies to produce a wide range of customisable products of extremely high quality, that are cost-effective. Whether you are an individual who wants personalised printed material for your family, or a business requiring branded printed materials for your employees or customers, you have a multitude of innovative designs to choose from. Our quick turnaround makes life that much easier for you.
</p>
<p className='footer-title'>To make it easy for you, we have:</p>
<p className='footer-title'>A user-friendly interface</p>
<p>Our website is intuitive, easy to navigate and has a clear layout of the products and services that we offer
</p>
<p className='footer-title'>Customisable free templates</p>
<p>Whether you’re ordering these items for the first time, or, you know what you want and don’t need a bespoke designing service, there’s a range of customisable design templates which are easy to use and
self-explanatory
</p>
<p className='footer-title'>Bespoke designing service</p>
<p>For those of you who know exactly what you want, and the templates don’t match up, we have a bespoke design service that will bring your vision to life, at a reasonable price. Call us for details</p>
<p className='footer-title'>Free Design-it-yourself interface</p>
<p>This is for our budding designers, or for those who want something different, but don’t have the budget to get a bespoke design done. We offer a versatile, free & easy-use design software that helps you create professional looking designs with ease.</p>
<p className='footer-title'>Print your own design</p>
<p>If you’ve already designed your artwork and would just like it printed, please upload your print-ready pdf in high resolution (300 Dpi) and we will print your file for you.</p>
<p className='footer-title'>Live WhatsApp assistance</p>
<p>Should you need some live help, contact us on the live WhatsApp assistance button and we will help you through the process
</p>
<p className='footer-title'>If we can help you achieve your print objective, we’ve achieved our objective!
</p>
        </div>
      </div>
      
      <Footer />
    </React.Fragment>
  );
};

export default about;
