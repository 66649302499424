import React, { useEffect, useState } from 'react';
import axios from 'axios';
const baseURL = process.env.REACT_APP_API_BASE_URL;

function Design() {
    const [formData, setFormData] = useState({
        product: '',
        dname: '',
        ds1: '',
        image: null,
        preview: null,
        price: '',
        min_qty: '',
        sku_design: '',
        designid: '',
        gst: '',
        total: '',
        type: '',
        op_type: '',
        // wno: '', // White design number
        // bno: ''  // Black design number
    });
    const [products, setProducts] = useState([]);
    const [message, setMessage] = useState('');
    const [fileUrl, setFileUrl] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await axios.get(`${baseURL}/fetch-product-admin`);
            setProducts(response.data);
            if (response.data.length > 0) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    product: response.data[0].pid
                }));
            }
        } catch (error) {
            console.log('Error fetching products:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData((prevFormData) => ({
            ...prevFormData,
            image: file,
            imageName: file ? file.name : ''
        }));
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setFileUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const totalCalculate = () => {
        const price = parseFloat(formData.price) || 0;
        const gst = parseFloat(formData.gst) || 0;
        const totalPrice = price + (price * gst / 100);

        setFormData((prevFormData) => ({
            ...prevFormData,
            total: totalPrice
        }));
    };

    useEffect(() => {
        totalCalculate();
    }, [formData.price, formData.gst]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const {
            product, dname, ds1, image, price,
            min_qty, sku_design, designid, total, gst, type, op_type
        } = formData;

        const formDataToSend = new FormData();
        formDataToSend.append('product', product);
        formDataToSend.append('dname', dname);
        formDataToSend.append('ds1', ds1);
        formDataToSend.append('price', price);
        formDataToSend.append('min_qty', min_qty);
        formDataToSend.append('sku_design', sku_design);
        formDataToSend.append('designid', designid);
        formDataToSend.append('gst', gst);
        formDataToSend.append('type', type);
        formDataToSend.append('op_type', op_type);
        // formDataToSend.append('wno', wno); // White design number
        // formDataToSend.append('bno', bno); // Black design number
        formDataToSend.append('total', total);
        if (image) formDataToSend.append('image', image);

        try {
            await axios.post(`${baseURL}/Design-Add`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setShowSuccessMessage(true); // Show success message

            // Reset form fields after 3 seconds
            setTimeout(() => {
                setFormData({
                    product: '',
                    dname: '',
                    ds1: '',
                    image: null,
                    preview: null,
                    price: '',
                    min_qty: '',
                    sku_design: '',
                    designid: '',
                    gst: '',
                    type: '',
                    op_type: '',
                    // wno: '', // Reset white design number
                    // bno: '', // Reset black design number
                    total: ''
                });
                setShowSuccessMessage(false); // Hide success message
            }, 3000);
        } catch (error) {
            setMessage('Error adding design.');
            console.error(error);
        }
    };

    return (
        <div className='container'>
            <h2>Add Design</h2>
            <form onSubmit={handleSubmit}>
                {showSuccessMessage && (
                    <div className="alert alert-success">
                        <strong>Success!</strong> The design has been added successfully.
                    </div>
                )}
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='form-group'>
                            <label>Image:</label>
                            <input
                                type='file'
                                className='form-control-file'
                                onChange={handleFileChange}
                            />
                            {formData.image && (
                                <div>
                                    <img src={fileUrl} alt="Preview" style={{ width: '20%' }} />
                                </div>
                            )}
                        </div>
                        <div className='form-group'>
                            <label>Name:</label>
                            <input
                                type='text'
                                className='form-control'
                                name='dname'
                                value={formData.dname}
                                onChange={handleInputChange}
                                
                            />
                        </div>
                        <div className='form-group'>
                            <label>Dimensions:</label>
                            <textarea
                                className='form-control'
                                name='ds1'
                                value={formData.ds1}
                                onChange={handleInputChange}
                                
                            ></textarea>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='form-group'>
                            <label>Product:</label>
                            <select
                                className='form-control'
                                name='product'
                                value={formData.product}
                                onChange={handleInputChange}
                                
                            >
                                <option value={""}>Select Product</option>
                                {products.map(p => (
                                    <option key={p.pid} value={p.pid}>
                                        {p.pname}
                                    </option>
                                ))}
                            </select>
                        </div>
                       
                        <div className='form-group'>
                            <label>Select Design Type:</label>
                            <p className='admin-note'>* If it is notebook for color dont select it.</p>
                            <select
                                className='form-control'
                                name='type'
                                value={formData.type}
                                onChange={handleInputChange}
                                
                            >
                                <option value={""}>Select Design Type</option>
                                <option value={"2"}>Design</option>
                                <option value={"1"}>UI</option>
                                <option value={"3"}>DIY</option>
                            </select>
                        </div>
                        <div className='form-group'>
                            <label>Design Operation Type:</label>
                            <p className='admin-note'>* Select as per mockup is personalisation or not.</p>
                            <select
                                className='form-control'
                                name='op_type'
                                value={formData.op_type}
                                onChange={handleInputChange}
                                
                            >
                                <option value={""}>Select Operation Type</option>
                                <option value={""}>Personalisation</option>
                                <option value={"b"}>Add To Cart</option>
                                
                            </select>
                        </div>
                        {/* <div className='form-group'>
                            <label>Design Number For White:</label>
                            <p className='admin-note'>* It is for t-shirt and frameprints.</p>
                            <input
                                type='text'
                                className='form-control'
                                name='wno'
                                value={formData.wno}
                                onChange={handleInputChange}
                                placeholder='Enter Design Number For White'
                                
                            />
                        </div>
                        <div className='form-group'>
                            <label>Design Number For Black:</label>
                            <p className='admin-note'>* It is for t-shirt and frameprints.</p>
                            <input
                                type='text'
                                className='form-control'
                                name='bno'
                                value={formData.bno}
                                onChange={handleInputChange}
                                placeholder='Enter Design Number For Black'
                                
                            />
                        </div> */}
                        <div className='form-group'>
                            <label>Sku Design:</label>
                            <input
                                type='text'
                                className='form-control'
                                name='sku_design'
                                value={formData.sku_design}
                                onChange={handleInputChange}
                                placeholder='#AD_P_001'
                                
                            />
                        </div>
                        <div className='form-group'>
                            <label>Design ID (In Editor):</label>
                            <p className='admin-note'>* If the mockup is personalisation then select it.</p>
                            <input
                                type='text'
                                className='form-control'
                                name='designid'
                                value={formData.designid}
                                onChange={handleInputChange}
                                placeholder='Enter Design Id'
                            />
                        </div>
                        <div className='form-group'>
                            <label>Min Quantity:</label>
                            <input
                                type='number'
                                className='form-control'
                                name='min_qty'
                                value={formData.min_qty}
                                onChange={handleInputChange}
                                
                            />
                        </div>
                        <div className='form-group'>
                            <label>Price:</label>
                            <p className='admin-note'>* Prcie without gst.</p>
                            <input
                                type='number'
                                className='form-control'
                                name='price'
                                value={formData.price}
                                onChange={handleInputChange}
                                
                            />
                        </div>
                        <div className='form-group'>
                            <label>GST:</label>r
                            <input
                                type='number'
                                className='form-control'
                                name='gst'
                                value={formData.gst}
                                onChange={handleInputChange}
                                
                            />
                        </div>
                        <div className='form-group'>
                            <label>Total Price:</label>
                            <input
                                type='text'
                                className='form-control'
                                name='total'
                                value={formData.total}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
                <button type='submit' className='btn btn-primary'>
                    Submit
                </button>
            </form>
        </div>
    );
}

export default Design;
