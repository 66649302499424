// DeleteLink.js
import React from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
const baseURL = process.env.REACT_APP_API_BASE_URL;

const DeleteLink = ({ cart_id, onDelete, showNotification }) => {
  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`${baseURL}/cart/delete/${cart_id}`);
      if (response.status === 200) {
        onDelete(cart_id); // Notify the parent component about the deletion
        showNotification('Successfully deleted'); // Show notification
        // You may navigate to another page after successful deletion if needed
      }
    } catch (error) {
      console.error('Error deleting order:', error);
      showNotification('Failed to delete'); // Notify user of deletion failure
    }
  };

  return (
    <button className="btn btn-danger" onClick={handleDelete}>
      <FontAwesomeIcon icon={faTrash} />
    </button>
  );
};

export default DeleteLink;
