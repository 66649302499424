// Layout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './sidebar';
import Navbar from './navbar';
import Footer from './footer';
import Order from './order'

const Layout = () => {
  return (
    <div className="layout">
      {/* <h1>This is the siderbar</h1> */}
      <Sidebar />
      <div className="main-content">
        <Navbar />
        <div className="content">
          <Order />
          <Outlet /> {/* This will render the matched route's component */}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
