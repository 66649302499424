import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Nav from './nav';
import Banner from './banner';
import Footer from './footer';
import Topbar from './top';

function Main() {
  const [countdown, setCountdown] = useState(5); // Start countdown from 5 seconds
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    if (countdown === 0) {
      clearInterval(timer);
      navigate('/'); // Redirect to the homepage or another page
    }

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, [countdown, navigate]);

  return (
    <div>
      <Nav />
      <Topbar />
      <div className="card-container">
        <div className="thank-you-card">
          <p>Thank you for placing the order.</p>
          <p>Redirecting in {countdown} seconds...</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
