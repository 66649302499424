import { useState, useEffect } from "react";
import React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const HomePage = () => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    const [data, setData] = useState([]);
    const { id } = useParams();
    const [productData, setProductData] = useState({});
    const [input, setInput] = useState({
        pid: '',
        email: id,
        username: '',
        rating: '',
        review: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseURL}/`);
                setData(response.data);
            } catch (err) {
                console.log(err);
            }
        };

        fetchData();
    }, [baseURL]);

    const handleReviewClick = async (pid) => {
        try {
            const result = await axios.get(`${baseURL}/product/${pid}`);
            setProductData(result.data);
            setInput((prevValue) => ({
                ...prevValue,
                pid: pid,
            }));
        } catch (err) {
            console.log(err);
        }
    };

    const Star = ({ filled, onClick, onMouseEnter, onMouseLeave, ariaLabel }) => {
        return (
            <span
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                style={{ 
                    cursor: 'pointer', 
                    color: filled ? '#FFD700' : '#C0C0C0', // gold for filled, gray for empty
                    fontSize: '24px'
                }}
                aria-label={ariaLabel}
                role="button"
            >
                ★
            </span>
        );
    };

    const StarRating = ({ totalStars = 5, onRatingChange }) => {
        const [rating, setRating] = useState(0);
        const [hoveredRating, setHoveredRating] = useState(0);

        const handleClick = (index) => {
            const newRating = rating === index + 1 ? 0 : index + 1;
            setRating(newRating);
            onRatingChange(newRating);
        };

        const handleMouseEnter = (index) => {
            setHoveredRating(index + 1);
        };

        const handleMouseLeave = () => {
            setHoveredRating(0);
        };

        return (
            <div>
                {Array.from({ length: totalStars }, (v, i) => (
                    <Star
                        key={i}
                        filled={i < (hoveredRating || rating)}
                        onClick={() => handleClick(i)}
                        onMouseEnter={() => handleMouseEnter(i)}
                        onMouseLeave={handleMouseLeave}
                        ariaLabel={`Star ${i + 1} ${i < (hoveredRating || rating) ? 'filled' : 'empty'}`}
                    />
                ))}
            </div>
        );
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setInput(prevValue => ({
            ...prevValue,
            [id]: value
        }));
    };

    const handleRatingChange = (newRating) => {
        setInput((prevValue) => ({
            ...prevValue,
            rating: newRating
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${baseURL}/rating`, input);
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="container">
            <div className="homepage">
                <div className="homepage-header">
                    <h3>Welcome, {id}</h3>
                </div>
                <div className="products">
                    {data.map((item, index) => (
                        <div key={item.pid} className="product-div">
                            <div className="image">
                                <img src={`${baseURL}/images/${item.image}`} alt={item.pname} />
                            </div>
                            <div className="title">
                                <p>{item.pname}</p>
                            </div>
                            <div className="description">
                                <p>{item.ds1}</p>
                            </div>
                            <div className="review">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleReviewClick(item.pid)}
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                >
                                    Write a review
                                </button>
                            </div>
                        </div>
                    ))}

                    <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Hello, {id}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="form-group">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-5">
                                                        <img className="reviewImg" src={`${baseURL}/images/${productData.image}`} alt={productData.pname} />
                                                    </div>
                                                    <div className="col-sm-7 right-side">
                                                        <p className="product-name">{productData.pname}</p>
                                                        <p className="product-desc">{productData.ds1}</p>
                                                        <StarRating totalStars={5} onRatingChange={handleRatingChange} />
                                                        <br />
                                                        <div className="inputs">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                onChange={handleChange}
                                                                placeholder="Enter your username"
                                                                id="username"
                                                            />
                                                        </div>
                                                        <br />
                                                        <div className="form-group inputs">
                                                            <textarea
                                                                className="form-control"
                                                                onChange={handleChange}
                                                                id="review"
                                                                placeholder="Write your review"
                                                            ></textarea>
                                                        </div>
                                                        <br />
                                                        <div className="buttons">
                                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                                            <button type="button" onClick={handleSubmit} className="btn btn-primary">Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
