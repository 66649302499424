import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const Main = () => {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleNavigation = (path) => {
        navigate(`/design/${path}`);
    };

    return (
        <React.Fragment>
            <div className='row topbar' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div className='container'>
                    <p className='personalise_title'>PERSONALISE</p>
                </div>
                <div className={`collapsible-container ${isHovered ? 'collapsed' : ''}`}>
                    <div className="content">
                        <div className='container'>
                            <div className='row top_nav_row hover-row-top'>
                                <div className='col-sm-4 ul_div'>
                                    <ul className='ul_sub_div'>
                                        <li className='top_ul' onClick={() => handleNavigation(1)}>Acrylic Desktops</li>
                                        <li className='top_ul' onClick={() => handleNavigation(2)}>Canvas Prints</li>
                                        <li className='top_ul' onClick={() => handleNavigation(3)}>Wooden Desktops</li>
                                        <li className='top_ul' onClick={() => handleNavigation(5)}>Notebooks</li>
                                        <li className='top_ul' onClick={() => handleNavigation(7)}>Coasters</li>
                                    </ul>
                                </div>
                                <div className='col-sm-4 ul_div'>
                                    <ul className='ul_sub_div'>
                                        <li className='top_ul' onClick={() => handleNavigation(8)}>Tablemats</li>
                                        <li className='top_ul' onClick={() => handleNavigation(9)}>Fridge Whiteboards</li>
                                        <li className='top_ul' onClick={() => handleNavigation(10)}>T-Shirts</li>
                                        <li className='top_ul' onClick={() => handleNavigation(24)}>Framed Prints</li>
                                    </ul>
                                </div>
                                <div className='col-sm-4 ul_div'>
                                    <div
                                        id="carouselExampleIndicators"
                                        className="carousel slide"
                                        data-ride="carousel"
                                        data-interval="1000"  // Set the slide interval to 2000 milliseconds (2 seconds)
                                    >
                                        {/* <ol className="carousel-indicators">
                                            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="8"></li>
                                        </ol> */}
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <img src={`${baseURL}/images/b1.webp`} className="d-block w-100" alt="Slide 1" />
                                            </div>
                                            <div className="carousel-item">
                                                <img src={`${baseURL}/images/b2.webp`} className="d-block w-100" alt="Slide 2" />
                                            </div>
                                            <div className="carousel-item">
                                                <img src={`${baseURL}/images/b3.webp`} className="d-block w-100" alt="Slide 3" />
                                            </div>
                                            <div className="carousel-item">
                                                <img src={`${baseURL}/images/b4.webp`} className="d-block w-100" alt="Slide 4" />
                                            </div>
                                            <div className="carousel-item">
                                                <img src={`${baseURL}/images/b5.webp`} className="d-block w-100" alt="Slide 5" />
                                            </div>
                                            <div className="carousel-item">
                                                <img src={`${baseURL}/images/b6.webp`} className="d-block w-100" alt="Slide 6" />
                                            </div>
                                            <div className="carousel-item">
                                                <img src={`${baseURL}/images/b7.webp`} className="d-block w-100" alt="Slide 7" />
                                            </div>
                                            <div className="carousel-item">
                                                <img src={`${baseURL}/images/b8.webp`} className="d-block w-100" alt="Slide 8" />
                                            </div>
                                        </div>
                                        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Main;
