// Dashboard.js
import React from 'react';
import Sidebar from './sidebar';
import Navbar from './navbar';
import Footer from './footer';
import Design from './design';
import Designpage from './designpage';

const Dashboard = () => {
  return (
    <div className="layout">
    <Sidebar />
    <div className="main-content">
      <Navbar />
      <div className="content">
      <Design />
    
      </div>
      <Footer />
    </div>
  </div>
  );
};

export default Dashboard;
