// Carousel.js
import React from 'react';
import Slider from 'react-slick';
import CustomArrow from './arrow';
import { Link } from 'react-router-dom';
import './slider.css';  // Ensure you have the CSS imports here

// Import static images
import img1 from '../images/slider/Acrylic Desktop (1).webp';
import img2 from '../images/slider/Canvas.webp';
import img3 from '../images/slider/Coaster.webp';
import img4 from '../images/slider/Framed Prints.webp';
import img5 from '../images/slider/Fridge whiteboard.webp';
import img6 from '../images/slider/Notebook.webp';
import img7 from '../images/slider/T-shirt.webp';
import img8 from '../images/slider/Tablemats.webp';
import img9 from '../images/slider/Wooden Desktop.webp';

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: true,
  nextArrow: <CustomArrow />,
  prevArrow: <CustomArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const carouselItems = [
  { img: img1, product: '1', product_name: 'Acrylic Desktops' },
  { img: img2, product: '2', product_name: 'Canvas Prints' },
  { img: img3, product: '7', product_name: 'Coasters' },
  { img: img4, product: '24', product_name: 'Framed Prints' },
  { img: img5, product: '9', product_name: 'Fridge Whiteboards' },
  { img: img6, product: '5', product_name: 'Notebooks' },
  { img: img7, product: '10', product_name: 'T-shirt' },
  { img: img8, product: '8', product_name: 'Tablemats' },
  { img: img9, product: '3', product_name: 'Wooden Desktops' }
];

const Carousel = () => {
  return (
    <div className='container'>
      <div className="carousel-container">
        <h3 className='explore_title'>Explore Personalised Gifts</h3>
        <Slider {...settings}>
          {carouselItems.map(slider => (
            <div key={slider.product} className='slick_div'>
              <Link to={`/design/${slider.product}`}>
                <div className='circle'>
                  <img
                    src={slider.img}
                    alt={`Slide ${slider.product}`}
                    className="circle-img"
                  />
                </div>
                <p className='slider-pname'>{slider.product_name}</p>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
      <hr className="split" />
    </div>
  );
};

export default Carousel;
