import React, { useState } from 'react';
import axios from 'axios';
const baseURL = process.env.REACT_APP_API_BASE_URL;

const Subscribe = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = async () => {
    try {
      const response = await axios.post(`${baseURL}/subscribe`, { email });
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Subscription failed: ' + error.response.data.error);
    } finally {
      setEmail('');  // Clear the email input after subscription attempt
    }
  };

  return (
    <React.Fragment>
      <div className='container subscribe_div'>
        <div className='row subscribe_row'>
        
            <p className='free-access'>Free access to our exclusive offers, ideas, and tips</p>
            </div>
            <div className='row subscirbe_row2'>
              <div className='email_div'>
                Email 
              </div>
              <div className='text-div'>
                <input
                  type='text'
                  className='form-control subscribe-top'
                  placeholder='Enter your email id'
                  name='email'
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              </div>
             
            <p className='button_div'>
              <button
                className='subscribe-top subscribe_button'
                onClick={handleSubscribe}
              >
                SUBSCRIBE
              </button>
            </p>
            {/* {message && <p>{message}</p>} */}
            </div>
        </div>
      <br />
    </React.Fragment>
  );
};

export default Subscribe;
