import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
const baseURL = process.env.REACT_APP_API_BASE_URL;

function Nav() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            const decoded = jwtDecode(token);
            setUser(decoded.userId);
            axios.get(`${baseURL}/session`, {
                headers: {
                    'Authorization': token
                }
            })
            .then(response => {
                if (response.data.success) {
                    setUser(response.data.userId);
                }
            })
            .catch(error => {
                console.error('Error checking session:', error);
            });
        }
    }, []);

    return (
        <div>
            {user ? <p>Welcome, User {user}</p> : <p>Please log in.</p>}
        </div>
    );
}

export default Nav;
