import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Sidebar from './sidebar';
import Navbar from './navbar';
import Footer from './footer';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const ProductForm = () => {
  const { pid } = useParams();
  const [formdata, setFormdata] = useState({
    pname: '',
    image: '',
    currentImage: '',
    preview: '',
    currentPreview: '',
    display: '',
    ds1: '',
    ds2: '',
    hsn: '',
    zone: '',
    machine: '',
    seq: '',
  });
  
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (pid) {
      axios.get(`${baseURL}/product/${pid}`)
        .then(response => {
          const product = response.data;
          setFormdata({
            pname: product.pname || '',
            image: '',
            currentImage: product.image || '',
            preview: '',
            currentPreview: product.preview || '',
            display: product.display || '',
            ds1: product.ds1 || '',
            ds2: product.ds2 || '',
            hsn: product.hsn || '',
            zone: product.zone || '',
            machine: product.machine || '',
            seq: product.seq || '',
          });
        })
        .catch(error => {
          console.error('Error fetching product data:', error);
        });
    }
  }, [pid]);

  useEffect(() => {
    axios.get(`${baseURL}/products`)
      .then(response => {
        setProducts(response.data);
      })
      .catch(error => {
        console.error('Error fetching products:', error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      setFormdata((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in formdata) {
      if (formdata[key] && (key !== 'image' && key !== 'preview')) {
        formData.append(key, formdata[key]);
      }
    }

    if (formdata.image) {
      formData.append('image', formdata.image);
    } else {
      formData.append('image', formdata.currentImage);
    }
    
    if (formdata.preview) {
      formData.append('preview', formdata.preview);
    } else {
      formData.append('preview', formdata.currentPreview);
    }

    try {
      let response;
      if (pid) {
        response = await axios.put(`${baseURL}/product/${pid}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        response = await axios.post(`${baseURL}/add-product`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      if (response.status === 200) {
        console.log(pid ? 'Product updated successfully:' : 'Product added successfully:', response.data);
      } else {
        console.error(pid ? 'Failed to update product:' : 'Failed to add product:', response.status);
      }
    } catch (error) {
      console.error(pid ? 'Error occurred while updating product:' : 'Error occurred while adding product:', error);
    }
  };

  return (
    <div className="layout">
      <Sidebar />
      <div className="main-content">
        <Navbar />
        <div className="content">
          <div className="container mt-4">
            <div className="product-form">
              <h2 className="mb-4">{pid ? 'Edit Product' : 'Add Product'}</h2>
              <form onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-sm-6'>
                    <div className="mb-3">
                      <label className="form-label">Product Name:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="pname"
                        value={formdata.pname}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Current Image:</label>
                      {formdata.currentImage && (
                        <img
                          src={`${baseURL}/images/${formdata.currentImage}`}
                          alt="Current Image"
                          style={{ width: '100px', height: '100px', marginBottom: '10px' }}
                        />
                      )}
                      <input
                        type="file"
                        className="form-control"
                        name="image"
                        onChange={handleFileChange}
                      />
                    </div>
                    
                    <div className="mb-3">
                      <label className="form-label">Current Preview:</label>
                      {formdata.currentPreview && (
                        <img
                          src={`${baseURL}/images/${formdata.currentPreview}`}
                          alt="Current Preview"
                          style={{ width: '100px', height: '100px', marginBottom: '10px' }}
                        />
                      )}
                      <input
                        type="file"
                        className="form-control"
                        name="preview"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <div className="mb-3">
                      <label className="form-label">Display:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="display"
                        value={formdata.display}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">DS1:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="ds1"
                        value={formdata.ds1}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">DS2:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="ds2"
                        value={formdata.ds2}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">HSN:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="hsn"
                        value={formdata.hsn}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Zone:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="zone"
                        value={formdata.zone}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Machine:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="machine"
                        value={formdata.machine}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Sequence:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="seq"
                        value={formdata.seq}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">{pid ? 'Update Product' : 'Add Product'}</button>
              </form>
            </div>
            {/* Display the products with images */}
           
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ProductForm;
