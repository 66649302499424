import React, { useState } from 'react';
import axios from 'axios';
import Sidebar from './sidebar';
import Navbar from './navbar';
import Footer from './footer';
const baseURL = process.env.REACT_APP_API_BASE_URL;

const ProductForm = () => {
  const [formdata, setFormdata] = useState({
    pname: '',
    image: '',
    preview: '',
    ds1: '',
    zone: '',
    machine: '',
   
  });

  // Handle change for text inputs and select dropdown
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      setFormdata((prevData) => ({
        ...prevData,
        [name]: files[0], // Store the file object directly
      }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    
  
  
    try {
      const response = await axios.post(`${baseURL}/add-product`, formdata, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
        },
      });
  
      if (response.status === 200) {
        console.log('Product added successfully:', response.data);
      } else {
        console.error('Failed to add product:', response.status);
      }
    } catch (error) {
      console.error('Error occurred while adding product:', error);
    }
  };

  return (
    <div className="layout">
      <Sidebar />
      <div className="main-content">
        <Navbar />
        <div className="content">
          <div className="container mt-4">
            <div className="product-form">
              <h2 className="mb-4">Add Product</h2>
              <form onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-sm-6'>
                    <div className="mb-3">
                      <label className="form-label">Product Name:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="pname"
                        value={formdata.pname}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Front-Image:</label>
                      <input
                        type="file"
                        className="form-control"
                        name="image"
                        onChange={handleFileChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Preview Image:</label>
                      <input
                        type="file"
                        className="form-control"
                        name="preview"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    
                    <div className="mb-3">
                      <label className="form-label">Description:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="ds1"
                        value={formdata.ds1}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Zone:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="zone"
                        value={formdata.zone}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Machine:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="machine"
                        value={formdata.machine}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">
                  Add Product
                </button>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ProductForm;
