import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
const baseURL = process.env.REACT_APP_API_BASE_URL;

const Table = () => {
    const { orderID } = useParams();
    const [placeorder, setPlaceorder] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseURL}/order-form/${orderID}`);
                 const orderformdata = response.data;
                setPlaceorder(orderformdata);
               
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [orderID]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading data.</p>;

    return (
        <div className="container table-container">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-4 order">
                    <h2 className="orderId">Order Id - {1000 + orderID}</h2>
                    <h4 className="orderDate">Date: {placeorder.placedOrder?.date || "N/A"}</h4>
                    </div>
                    <div className="col-sm-4">
                        <div className="logo-div">
                            <img className="logo" src={`${baseURL}/images/Vprint.webp`} alt="Logo" /><br />
                        </div>
                        <div className="challan">
                            <p>Challan</p>
                        </div>
                    </div>
                    <div className="col-sm-4 zone">
                        <div>
                            <h2>Zone-{placeorder.product?.zone || "1"}</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <table>
                    <tbody>
                        <tr>
                            <th>Vendor Name</th>
                            <td>Vareprint</td>
                            <th>Vendor Email</th>
                            <td>support@vareprint.com</td>
                            <th>Vendor Contact</th>
                            <td>9987739002</td>
                        </tr>
                        <tr>
                            <th>Customer Name</th>
                            <td>{placeorder.user?.firstName} {placeorder.user?.lastName || ""}</td>
                            <th>Customer Email</th>
                            <td>{placeorder.user?.email || ""}</td>
                            <th>Customer Contact</th>
                            <td>{placeorder.user?.contact || ""}</td>
                        </tr>
                        {/* <tr>
                            <th>Customer Company Name</th>
                            <td colSpan="2">{placeorder.user?.companyName || ""}</td>
                            <th>Customer Gst No</th>
                            <td colSpan="2">{placeorder.user?.gstNo || ""}</td>
                        </tr> */}
                    </tbody>
                </table>

                <table>
                    <thead>
                        <tr>
                            <th>HSN No.</th>
                            <th>Machine Name</th>
                            <th width="60%">Name of Product</th>
                            <th>Quantity</th>
                            <th>Amount Paid</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{placeorder.product?.hsn}</td>
                            <td>{placeorder.product?.machine || "N/A"}</td>
                            <td>
                                Product Name - {placeorder.product?.pname}<br />
                                {/* Specifications below:<br />
                                // {placeorder.product?.ds1}<br /> */}
                              {placeorder.order?.size && (
                                <>
                                <span>Size: {placeorder.order.size}</span><br />
                                </>
                            )}

                             {placeorder.order?.color && (
                                <>
                                <span>Color: {placeorder.order.color}</span><br />
                                </>
                            )}
                               {placeorder.design?.ds1}<br />
                               
                            </td>
                            <td>{placeorder.order?.quantity || "N/A"}</td>
                            <td>₹{placeorder.order?.total || "N/A"}</td>
                        </tr>
                        <tr>
                            <th colSpan="2">Paper-Type:</th>
                            <td colSpan="3">{placeorder.design?.ds1 || "N/A"}</td>
                        </tr>
                        <tr>
                            <th colSpan="2">Description:</th>
                            <td colSpan="3">{placeorder.product?.ds2 || "N/A"}</td>
                        </tr>
                        <tr>
                            <th colSpan="2">Shipping Address Info:</th>
                            <td colSpan="3">{placeorder.user?.address || "N/A"}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
<br />
            <div className="container-fluid order-rep">
                <div className="row row-for-order">
                    <div className="col-sm-4">
                        <h3>Order Id: {1000 + orderID}</h3>
                        <p>Product: {placeorder.product?.pname || "N/A"}<br />
                            Quantity: {placeorder.order?.quantity || "N/A"}<br />
                            AWB Number: {placeorder.placedOrder?.awb}<br />
                        </p>
                    </div>
                    <div className="col-sm-4">
                        <h3>Order Id: {1000 + orderID}</h3>
                        <p>Product: {placeorder.product?.pname || "N/A"}<br />
                            Quantity: {placeorder.order?.quantity || "N/A"}<br />
                            AWB Number:{placeorder.placedOrder?.awb}<br />
                        </p>
                    </div>
                    <div className="col-sm-4">
                        <h3>Order Id: {1000 + orderID}</h3>
                        <p>Product: {placeorder.product?.pname || "N/A"}<br />
                            Quantity: {placeorder.order?.quantity || "N/A"}<br />
                            AWB Number: {placeorder.placedOrder?.awb}<br />
                        </p>
                    </div>
                </div>
            </div>
            <br />
                        <div className="container-fluid product-design">
                <div className="row ">
                    <div className="col-sm-4 text-center">
                        <h3>Product Design:</h3>
                    </div>
                    <div className="col-sm-8 img-div">
    <img 
        src={placeorder.order?.imagename1 ?
            placeorder.order.imagename1 :  `${baseURL}/images/design/${placeorder.design?.images}` 
           } 
        alt="Product Design" 
    />
</div>
                </div>
            </div>
        </div>
    );
};

export default Table;
