// Profile.js
import React from 'react';
import Sidebar from './sidebar';
import Navbar from './navbar';
import Footer from './footer';


const Profile = () => {
  return (
    <div className="layout">
    <Sidebar />
    <div className="main-content">
      <Navbar />
      <div className="content">
     <p>THis is profile page</p>
      </div>
      <Footer />
    </div>
  </div>
  );
};

export default Profile;
