import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './design.css';
import Nav from './nav';
import Footer from './footer';
import { Link } from 'react-router-dom';
import Top from './top';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const Design = () => {
  const { productId } = useParams();
  const [designs1, setDesigns1] = useState([]);
  const [designs2, setDesigns2] = useState([]);
  const [designs3, setDesigns3] = useState([]);
  const [product, setProduct] = useState('');

  useEffect(() => {
    // Fetch product details and related designs based on productId
    axios.get(`${baseURL}/product/design1/${productId}`)
      .then(response => {
        setDesigns1(response.data.designs || []); // Ensure designs is an array
       
      })
      .catch(error => {
        console.error('Error fetching product details:', error);
      });

    axios.get(`${baseURL}/product/design2/${productId}`)
      .then(response => {
        setDesigns2(response.data.designs || []); // Ensure designs is an array
        if (response.data.designs.length > 0) {
          setProduct(response.data.designs[0].pname);
        }
      })
      .catch(error => {
        console.error('Error fetching product details:', error);
      });

    axios.get(`${baseURL}/product/design3/${productId}`)
      .then(response => {
        setDesigns3(response.data.designs || []); // Ensure designs is an array
      })
      .catch(error => {
        console.error('Error fetching product details:', error);
      });
  }, [productId]);

  return (
    <React.Fragment>
      <Nav />
      <Top />
      <div className='container'>
        <ul className='filter_ul'>
          <Link to={`/`}>
            <li>Home </li>
          </Link>
          <li>&nbsp;/&nbsp;</li>
          <li>{product}</li>
        </ul>
      </div>
      <div className='container design-container'>
        <div className='row'>
          {designs1.map(design => (
            <div key={design.did} className='col-sm-3 design_div'>
              <Link to={`/preview/${design.did}`}>
                <img src={`${baseURL}/images/design/${design.images}`} alt={design.pname} className='design_image' />
                <p className='sku_design'>{design.sku_design}</p>
                <p className='design_name'>{design.dname}</p>
              </Link>
              <p className='design_ds'>{design.ds1}</p>
             
              <p className='price_s'>&#8377; {design.total} <span className='discount-pirce'>&nbsp; &#8377; {Math.floor(design.total * 1.2)}</span><img src={`${baseURL}/images/20OFF.webp`} className="offer-image" /></p>
            </div>
          ))}
          {designs2.map(design => (
            <div key={design.did} className='col-sm-3 design_div'>
              
              {design.did== '81' ? (
                 <Link to={`/notebook/5`}>
                 <img src={`${baseURL}/images/design/${design.images}`} alt={design.pname} className='design_image' />
                 <p className='sku_design'>{design.sku_design}</p>
                 <p className='design_name'>{design.dname}</p>
               </Link>
              ): (
                <Link to={`/preview/${design.did}`}>
                <img src={`${baseURL}/images/design/${design.images}`} alt={design.pname} className='design_image' />
                <p className='sku_design'>{design.sku_design}</p>
                <p className='design_name'>{design.dname}</p>
              </Link>
              )}
             
              <p className='design_ds'>{design.ds1}</p>
              <p className='price_s'> &#8377; {design.total} <span className='discount-pirce'>&nbsp; &#8377; {Math.floor(design.total * 1.2)}</span> <img src={`${baseURL}/images/20OFF.webp`} className="offer-image" /></p>
            </div>
          ))}
          {designs3.map(design => (
            <div key={design.did} className='col-sm-3 design_div'>
              <Link to={`/preview/${design.did}`}>
                <img src={`${baseURL}/images/design/${design.images}`} alt={design.pname} className='design_image' />
                <p className='sku_design'>{design.sku_design}</p>
                <p className='design_name'>{design.dname}</p>
              </Link>
              <p className='design_ds'>{design.ds1}</p>
              {/* <p className='price_s'>&#8377; {design.total} <span className='discount-pirce'>&nbsp; &#8377; {Math.floor(design.total * 1.2)}</span><img src={`${baseURL}/images/20OFF.webp`} className="offer-image" /></p> */}
            </div>
          ))}
        </div>
      </div>
      <br />
      <Footer />
    </React.Fragment>
  );
};

export default Design;
