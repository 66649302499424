import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Sidebar from './sidebar';
import Navbar from './navbar';
import Footer from './footer';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const DesignForm = () => {
  const { did } = useParams(); // design ID from the URL
  const [formdata, setFormdata] = useState({
    dname: '',
    product: '',
    designid: '',
    sku_design: '',
    ds1: '',
    type: '',
    op_type: '',
    min_qty: '',
    images: null,
    currentImages: '',
    preview: null,
    currentPreview: '',
    total: '',
    price: '',
    rate: '',
    gst: '',
    whiteSize:'',
    whiteImage: null,
    currentWhiteImage: '',
    blackSize:'',
    blackImage: null,
    currentBlackImage: '',
    yellowSize:'',
    yellowImage: null,
    currentYellowImage: '',
    whiteDesignId: '', // New field
    blackDesignId: '',
    yellowDesignId: '', // New field
    whitePrice: '',
    blackPrice: '',
    yellowPrice: '',
  });

  const [products, setProducts] = useState([]);

  // Fetch all products
  useEffect(() => {
    axios.get(`${baseURL}`)
      .then(response => {
        setProducts(response.data);
      })
      .catch(error => {
        console.error('Error fetching products:', error);
      });
  }, []);

  // Fetch design data if 'did' is present
  useEffect(() => {
    if (did) {
      axios.get(`${baseURL}/design/${did}`)
        .then(response => {
          const design = response.data;
          setFormdata({
            dname: design.dname || '',
            product: design.product || '',
            designid: design.designid || '',
            sku_design: design.sku_design || '',
            ds1: design.ds1 || '',
            type: design.type || '',
            op_type: design.op_type || '',
            min_qty: design.min_qty || '',
            images: null,
            currentImages: design.images || '',
            preview: null,
            currentPreview: design.preview || '',
            total: design.total || '',
            price: design.price || '',
            rate: design.rate || '',
            gst: design.gst || '',
            whiteSize: design.whiteSize || '',
            whiteImage: null,
            currentWhiteImage: design.whiteImage || '',
            blackSize: design.blackSize || '',
            blackImage: null,
            currentBlackImage: design.blackImage || '',
            yellowSize: design.yellowSize || '',
            yellowImage: null,
            currentYellowImage: design.yellowImage || '',
            whiteDesignId: design.whiteDesignId || '', // Initialize field
            blackDesignId: design.blackDesignId || '',
            yellowDesignId: design.yellowDesignId|| '',  // Initialize field
            whitePrice: design.whitePrice || '',
            blackPrice: design.blackPrice || '',
            yellowPrice: design.yellowPrice || '',
          });
        })
        .catch(error => {
          console.error('Error fetching design data:', error);
        });
    }
  }, [did]);

  // Function to calculate the total price
  const totalCalculate = () => {
    const price = parseFloat(formdata.price) || 0;
    const gst = parseFloat(formdata.gst) || 0;
    const totalPrice = price + (price * gst / 100);

    setFormdata(prevFormdata => ({
      ...prevFormdata,
      total: totalPrice // Ensure total is formatted to 2 decimal places
    }));
  };

  // Update total price whenever price or gst changes
  useEffect(() => {
    totalCalculate();
  }, [formdata.price, formdata.gst]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormdata(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      setFormdata(prevData => ({
        ...prevData,
        [name]: files[0],
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
  
    Object.keys(formdata).forEach(key => {
      if (key !== 'currentImages' && key !== 'currentPreview' && key !== 'currentWhiteImage' && key !== 'currentBlackImage' && key !== 'currentYellowImage') {
        if (formdata[key] !== null && formdata[key] !== '') {
          formData.append(key, formdata[key]);
        }
      }
    });
  
    // Append images and previews only if new files are selected
    if (formdata.images) formData.append('images', formdata.images);
    else if (formdata.currentImages) formData.append('images', formdata.currentImages);
    
    if (formdata.preview) formData.append('preview', formdata.preview);
    else if (formdata.currentPreview) formData.append('preview', formdata.currentPreview);
  
    if (formdata.whiteImage) formData.append('whiteImage', formdata.whiteImage);
    else if (formdata.currentWhiteImage) formData.append('whiteImage', formdata.currentWhiteImage);
  
    if (formdata.blackImage) formData.append('blackImage', formdata.blackImage);
    else if (formdata.currentBlackImage) formData.append('blackImage', formdata.currentBlackImage);

    if (formdata.yellowImage) formData.append('yellowImage', formdata.yellowImage);
    else if (formdata.currentYellowImage) formData.append('yellowImage', formdata.currentYellowImage);
  
    try {
      let response;
      if (did) {
        response = await axios.put(`${baseURL}/update-design/${did}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        response = await axios.post(`${baseURL}/add-design`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }
  
      if (response.status === 200) {
        console.log(did ? 'Design updated successfully:' : 'Design added successfully:', response.data);
      } else {
        console.error(did ? 'Failed to update design:' : 'Failed to add design:', response.status);
      }
    } catch (error) {
      console.error(did ? 'Error occurred while updating design:' : 'Error occurred while adding design:', error);
    }
  };

  return (
    <div className="layout">
      <Sidebar />
      <div className="main-content">
        <Navbar />
        <div className="content">
          <div className="container mt-4">
            <div className="design-form">
              <h2 className="mb-4">{did ? 'Edit Design' : 'Add Design'}</h2>
              <form onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-sm-6'>
                    <div className="mb-3">
                      <label className="form-label">Design Name:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="dname"
                        value={formdata.dname}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Product:</label>
                      <select
                        className="form-control"
                        name="product"
                        value={formdata.product}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Product</option>
                        {products.map((product) => (
                          <option key={product.pid} value={product.pid}>
                            {product.pname}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Design Mockup Image:</label>
                      {formdata.currentImages && (
                        <img
                          src={`${baseURL}/images/design/${formdata.currentImages}`}
                          alt="Current Image"
                          style={{ width: '100px', height: '100px', marginBottom: '10px' }}
                        />
                      )}
                      <input
                        type="file"
                        className="form-control"
                        name="images"
                        onChange={handleFileChange}
                      />
                    </div>
                     <p className='section2'>Preview Page - For color and sizes images</p>
                    <div className="mb-3">
                      <label className="form-label">Design Preview:</label>
                      {formdata.currentPreview ? (
  <img
    src={`${baseURL}/images/${formdata.currentPreview}`}
    alt="Current Preview"
    style={{ width: '100px', height: '100px', marginBottom: '10px' }}
  />
) : (
  <img
    src={`${baseURL}/images/design/${formdata.currentImages}`}
    alt="Current Image"
    style={{ width: '100px', height: '100px', marginBottom: '10px' }}
  />
)}
                      <input
                        type="file"
                        className="form-control"
                        name="preview"
                        onChange={handleFileChange}
                      />
                    </div>
                    
                    <p className='section-size2'>Size-1 Detials it can be used for also white color inside t-shirt and framed Prints.</p>
                    <hr />
                    <div className="mb-3">
                      <label className="form-label size-title">Size-1 :</label>
                      <input
                        type="text"
                        className="form-control"
                        name="whiteSize"
                        value={formdata.whiteSize}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Size-1 / White Image:</label>
                      {formdata.currentWhiteImage && (
                        <img
                          src={`${baseURL}/images/design/${formdata.currentWhiteImage}`}
                          alt="White Image"
                          style={{ width: '100px', height: '100px', marginBottom: '10px' }}
                        />
                      )}
                      <input
                        type="file"
                        className="form-control"
                        name="whiteImage"
                        onChange={handleFileChange}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Size-1 / White Price:</label>
                      <p className="admin-note">*  Price Without Gst.</p>
                      <input
                        type="text"
                        className="form-control"
                        name="whitePrice"
                        value={formdata.whitePrice}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Size-1 / White Design ID:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="whiteDesignId"
                        value={formdata.whiteDesignId}
                        onChange={handleChange}
                      />
                    </div>

                    <p className='section-size2'>Size-2 Detials it can be used for also black color inside t-shirt and framed Prints.</p>

                    <hr />
                    <div className="mb-3">
                      <label className="form-label size-title">Size-2 :</label>
                      <input
                        type="text"
                        className="form-control"
                        name="blackSize"
                        value={formdata.blackSize}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Size-2 / Black Image:</label>
                      {formdata.currentBlackImage && (
                        <img
                          src={`${baseURL}/images/design/${formdata.currentBlackImage}`}
                          alt="Black Image"
                          style={{ width: '100px', height: '100px', marginBottom: '10px' }}
                        />
                      )}
                      <input
                        type="file"
                        className="form-control"
                        name="blackImage"
                        onChange={handleFileChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Size-2 / Black Design ID:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="blackDesignId"
                        value={formdata.blackDesignId}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Size-2 /Black Price:</label>
                      <p className="admin-note">*  Price Without Gst.</p>
                      <input
                        type="text"
                        className="form-control"
                        name="blackPrice"
                        value={formdata.blackPrice}
                        onChange={handleChange}
                      />
                    </div>
                    <p className='section-size2'>Size-3 Detials.</p>

                    <hr />
                    <div className="mb-3">
                      <label className="form-label size-title">Size-3 :</label>
                      <input
                        type="text"
                        className="form-control"
                        name="yellowSize"
                        value={formdata.yellowSize}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Size-3 Images:</label>
                      {formdata.currentYellowImage && (
                        <img
                          src={`${baseURL}/images/design/${formdata.currentYellowImage}`}
                          alt="Yellow Image"
                          style={{ width: '100px', height: '100px', marginBottom: '10px' }}
                        />
                      )}
                      <input
                        type="file"
                        className="form-control"
                        name="yellowImage"
                        onChange={handleFileChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Size-3 Design ID:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="yellowDesignId"
                        value={formdata.yellowDesignId}
                        onChange={handleChange}
                      />
                    </div>
                    
                   
                    <div className="mb-3">
                      <label className="form-label">Size-3 Price:</label>
                      <p className="admin-note">*  Price Without Gst.</p>
                      <input
                        type="text"
                        className="form-control"
                        name="yellowPrice"
                        value={formdata.yellowPrice}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <div className="mb-3">
                      <label className="form-label">Design ID:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="designid"
                        value={formdata.designid}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">SKU Design:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="sku_design"
                        value={formdata.sku_design}
                        onChange={handleChange}
                        
                      />
                    </div>
                    
                    <div className="mb-3">
                      <label className="form-label">Design Dimensions:</label>
                    
                      <input
                        type="text"
                        className="form-control"
                        name="ds1"
                        value={formdata.ds1}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
  <p className="admin-note">* Select Design type.</p>
  <select
    className="form-control"
    name="type"
    onChange={handleChange}
    value={formdata.type === "3" ? "3" : formdata.type || ""}
  >
    <option value="" disabled>{formdata.type ? 'Selected Design Type' : 'Select Design Type'}</option>
    <option value="1">UI Design</option>
    <option value="2">Design</option>
    <option value="3">DIY</option>
  </select>
</div>

                  
                    <div className="mb-3">
                    <label>Design Operation Type:</label>
                    
                    <p class="admin-note">* Select as per mockup is personalisation or not.</p>
                    <select
  className="form-control"
  name="op_type"
  onChange={handleChange}
  value={formdata.op_type === "b" ? "b" : formdata.op_type || ""}
>
  <option value="" disabled>{formdata.op_type ? 'Selected Operation Type' : 'Select Operation Type'}</option>
  <option value="personalisation">Personalisation</option>
  <option value="b">Add To Cart</option>
</select>

                     
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Minimum Quantity:</label>
                      <input
                        type="number"
                        className="form-control"
                        name="min_qty"
                        value={formdata.min_qty}
                        onChange={handleChange}
                      />
                    </div>
                  
                   
                   
                    <div className="mb-3">
                      <label className="form-label">Price:</label>
                      <input
                        type="number"
                        className="form-control"
                        name="price"
                        value={formdata.price}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">GST:</label>
                      <input
                        type="number"
                        className="form-control"
                        name="gst"
                        value={formdata.gst}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Total:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="total"
                        value={formdata.total}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">
                  {did ? 'Update Design' : 'Add Design'}
                </button>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default DesignForm;
