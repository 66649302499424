import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
const baseURL = process.env.REACT_APP_API_BASE_URL;

const Orders = () => {
  const [ordersWithDetails, setOrdersWithDetails] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        // Fetch all placed orders
        const placedOrdersResponse = await axios.get(`${baseURL}/placed-orders`);
        const placedOrdersData = placedOrdersResponse.data || [];
        
        console.log('Placed orders data:', placedOrdersData); // Debug log
    
        // Fetch additional details for each order by order_id
        const ordersWithDetailsData = await Promise.all(
          placedOrdersData.map(async (placedOrder) => {
            try {
              console.log('Fetching details for order_id:', placedOrder.order_id);
    
              // Fetch order details
              const orderDetails = await axios.get(`${baseURL}/orders-fetch/${placedOrder.order_id}`).then(res => {
                console.log('Order details:', res.data); // Debug log
                return res.data;
              });
    
              // Fetch design details
              const designDetails = await axios.get(`${baseURL}/design-fetch-admin/${orderDetails.did}`).then(res => {
                console.log('Design details:', res.data); // Debug log
                return res.data;
              });
    
              // Fetch product details
              const productDetails = await axios.get(`${baseURL}/product-fetch/${orderDetails.pid}`).then(res => {
                console.log('Product details:', res.data); // Debug log
                return res.data;
              });
    
              // Fetch user details
              const userDetails = await axios.get(`${baseURL}/user-fetch/${orderDetails.user_id}`).then(res => {
                console.log('User details:', res.data); // Debug log
                return res.data;
              });
    
              // Combine all details into one object
              return {
                ...placedOrder,
                order: orderDetails,
                design: designDetails,
                product: productDetails,
                user: userDetails
              };
            } catch (error) {
              console.error(`Error fetching details for order_id ${placedOrder.order_id}:`, error);
              return placedOrder; // Return the placedOrder as a fallback
            }
          })
        );
    
        console.log('Orders with details data:', ordersWithDetailsData); // Debug log
        setOrdersWithDetails(ordersWithDetailsData);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
    
    
    fetchOrders();
  }, []);

  return (
    <div className="main-content">
      <div className="content">
        <div className='container'>
          <h2>Orders</h2>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Order ID</th> 
                  <th>Order Date</th>
                  <th>User Name</th>
                  <th>User Email</th>
                  <th>Image</th>
                  <th>PDF</th>
                  <th>AWB</th>
                  <th>Invoice</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>GST Amount</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {ordersWithDetails.length > 0 ? (
                  ordersWithDetails.map(placedOrder => (
                    <tr>
                      <td>
                        <Link target="_blank" to={`/order-form/${placedOrder.p_o_id}`}>
                                {10000 + placedOrder.p_o_id}
                            </Link>
                            
                      </td>
                      <td>{placedOrder.date || 'No Order Date'}</td>
                      <td>{placedOrder.name || 'No User Name'}</td>
                      <td>{placedOrder.p_email || 'No User Email'}</td>
                      <td>
                         {placedOrder.order?.imagename1 ? (
                             <img 
                              src={`${placedOrder.order?.imagename1}`} 
                              alt="Product Image" 
                                width="100" 
                              />
                           ) : (
                            <img 
                            src={`${baseURL}/images/design/${placedOrder.design?.images}`} 
                            alt="Product Image" 
                              width="100" 
                            />
                      )}
                      </td>
                      <td><Link target='blank' to={`https://pdf.pitchprint.io/?id=${placedOrder.order?.img_name}`}>PDF</Link></td>
                      <td>
                      {placedOrder.awb ? (
                                <Link to ={`${baseURL}/awb_pdfs/${placedOrder.awb}.pdf`} target="_blank" >
                                  {placedOrder.awb}
                                </Link>
                            ) : (
                                'No PDF Available'
                            )}
                            </td>
                            <td>
                            <Link to={`/get-invoice/${placedOrder.invoice_number}`} target="_blank">
                                {placedOrder.invoice_number}
                            </Link>
                            </td>
                      <td>{placedOrder.order?.withoutgstprice || 'No Price'}</td>
                      <td>{placedOrder.order?.quantity || 'No Quantity'}</td>
                      <td>{placedOrder.order?.gst_amount || 'No GST Amount'}</td>
                      <td>{placedOrder.order?.total || 'No Total'}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="13">No orders found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
