import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const baseURL = process.env.REACT_APP_API_BASE_URL;

function Invoice() {
    
    const { invoiceID } = useParams();
    const [invoiceData, setInvoiceData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const generatePDF = (invoiceID) => {
        // Ensure invoiceID is valid
        if (!invoiceID) {
            console.error('Invoice ID is missing');
            return;
        }
    
        const input = document.getElementById('pdf-content');
        html2canvas(input, { scale: 2, useCORS: true }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            
            // Increase the height of the PDF
            const extraHeight = -10; // Adjust this value if needed
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width + extraHeight;
            
            // Adjust the Y coordinate to add padding
            const padding = 10; // Adjust this value if needed
            const yCoordinate = padding;
    
            // Add image to PDF with padding
            pdf.addImage(imgData, 'PNG', padding, yCoordinate, pdfWidth - 2 * padding, pdfHeight - 2 * padding);
    
            // Convert the PDF to a Blob
            const pdfBlob = pdf.output('blob');
    
            // Create a FormData object to send the Blob
            const formData = new FormData();
            const fileName = `invoice-${invoiceID}.pdf`;
            console.log('Generated PDF file name:', fileName); // Verify the file name
            formData.append('pdf', pdfBlob, fileName);
    
            // Send the FormData to the server
            axios.post(`${baseURL}/save-invoice`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(() => {
                console.log('Invoice PDF saved successfully.');
            })
            .catch((error) => {
                console.error('Error saving invoice PDF:', error);
            });
        }).catch((error) => {
            console.error('Error generating PDF:', error);
        });
    };
    
   
    const numberToWords = (num) => {
        const a = [
          '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine',
          'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen',
          'Seventeen', 'Eighteen', 'Nineteen'
        ];
        const b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
      
        const convertHundreds = (num) => {
          if (num > 99) {
            return a[Math.floor(num / 100)] + ' Hundred ' + convertTens(num % 100);
          }
          return convertTens(num);
        };
      
        const convertTens = (num) => {
          if (num < 20) return a[num];
          return b[Math.floor(num / 10)] + ' ' + a[num % 10];
        };
      
        const convertThousands = (num) => {
          if (num > 999) {
            return convertHundreds(Math.floor(num / 1000)) + ' Thousand ' + convertHundreds(num % 1000);
          }
          return convertHundreds(num);
        };
      
        if (num === 0) return 'Zero';
        return convertThousands(num).trim();
      };
       

    useEffect(() => {
        const fetchInvoiceData = async () => {
            try {
                const response = await axios.get(`${baseURL}/get-invoice-data/${invoiceID}`);
                const data = response.data;
                setInvoiceData(data);
                setLoading(false);
                // Generate PDF after data is set
                setTimeout(() => generatePDF(invoiceID), 1000);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchInvoiceData();
    }, [invoiceID]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading invoice data.</p>;

    const { placedOrder, order, user, product, design } = invoiceData || {};

    return (
        <div className='container invoice_container'>
            <br />
            <div className='card card-invoice' id="pdf-content">
                <div className="logo-div">
                    <img className="logo" src={`${baseURL}/images/Vprint.webp`} alt="Logo" /><br />
                </div>
                <p className='tax-invoice-title'>Tax Invoice</p>

                <table className='invoice_table'>
                    <tbody>
                        <tr>
                            <th rowSpan='3'>
                                <p className='heading1'>Bill From:<br /></p>VAREPRINT PRIVATE LIMITED, <br />
                                <p className='heading2'>
                                    B-412, 4th Floor, Durian Estate, Goregaon - Mulund Link Road, Goregaon East, Mumbai 400063<br />
                                    PAN No. : AAHCV6961F <br />
                                    GSTIN : 27AAHCV6961F1ZY <br />
                                    State Name : Maharashtra, Code : 27 <br />
                                    Email : support@vareprint.com
                                </p>
                            </th>
                            <th>
                                <p className='heading1'>Invoice No.</p>
                                <p className='heading2'>{placedOrder?.invoice_number || 'No Number'}</p>
                            </th>
                            <th>
                                <p className='heading1'>Dated</p>
                                <p className='heading2'>{new Date(placedOrder?.confirm_date).toLocaleDateString()}</p>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <p className='heading1'>Delivery Order Id</p>
                                <p className='heading2'>{1000 + placedOrder?.p_o_id}</p>
                            </th>
                            <th>
                                <p className='heading1'>Destination</p>
                                <p className='heading2'>{`${user?.city}, ${user?.state}`}</p>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <p className='heading1'>Dispatched through</p>
                                <p className='heading2'>Courier</p>
                            </th>
                            <th>
                                <p className='heading1'>AWB No.</p>
                                <p className='heading2'>{placedOrder?.awb}</p>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <p className='heading1'>Bill To:</p>
                                <p className='heading2'>{user?.b_name} <br />
                                    {`${user?.b_address}, ${user?.b_city}, ${user?.b_state} - ${user?.b_postcode}, ${user?.b_country}`} <br />
                                    Contact: {user?.b_contact}<br />
                                    Email: {user?.b_email}<br />
                                </p>
                            </th>
                            <th colSpan={2}>
                                <p className='heading1'>Ship To:</p>
                                <p className='heading2'>{user?.firstName}<br />
                                    {`${user?.shipp_address}, ${user?.city}, ${user?.state} - ${user?.postcode}, ${user?.country}`}<br />
                                    Contact: {user?.contact}
                                </p>
                            </th>
                        </tr>
                    </tbody>
                </table>

                <table>
                    <thead>
                        <tr>
                            <th>Sr No.</th>
                            <th>Description of Goods</th>
                            <th>HSN Code</th>
                            <th>Quantity</th>
                            <th>Unit Rate</th>
                            <th>Discount</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><p className='design_name-invoice'>1</p></td>
                            <td><p className='design_name-invoice'>{design?.dname}<br />{order?.size ? <span>Size: {order.size}</span> : null} <br />{order?.color ? <span>Color: {order.color}</span> : null}</p></td>
                            <td><p className='design_name-invoice'>{product?.hsn}</p></td>
                            <td><p className='design_name-invoice'>{order?.quantity}</p></td>
                            <td><p className='design_name-invoice'>{order?.withoutgstprice ?? design?.price}</p></td>
                            <td><p className='design_name-invoice'>0%</p></td>
                            <td><p className='design_name-invoice'>{order?.withoutgstprice}</p></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>CGST</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>₹{(order?.gst_amount / 2).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>SGST</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>₹{(order?.gst_amount / 2).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Total</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>₹{order?.total}</td>
                        </tr>
                    </tbody>
                </table>

                <table>
                    <thead>
                        <tr>
                            <th rowSpan={2}>HSN Code</th>
                            <th rowSpan={2}>Taxable Value</th>
                            <th colSpan={2}>Central Tax</th>
                            <th colSpan={2}>State Tax</th>
                            <th colSpan={2}>Integrated Tax</th>
                            <th rowSpan={2}>Total Tax Amount</th>
                        </tr>
                        <tr>
                            <th>Rate</th>
                            <th>Amount</th>
                            <th>Rate</th>
                            <th>Amount</th>
                            <th>Rate</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{product?.hsn}</td>
                            <td>₹{order?.withoutgstprice}</td>
                            <td>{(design?.gst / 2)}%</td>
                            <td>₹{(order?.gst_amount / 2).toFixed(2)}</td>
                            <td>{(design?.gst / 2)}%</td>
                            <td>₹{(order?.gst_amount / 2).toFixed(2)}</td>
                            <td></td>
                            <td></td>
                            <td>₹{order?.gst_amount}</td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <tbody>
                        
                        <tr>
                            <td colSpan={9}>
                               <p className='instruction-invoice1'>Tax Amount (in words):  {numberToWords(order?.total || 0)} Only
                               <br /> Whether tax is payable under reverse charge - No</p> 
                            </td>
                            
                        </tr>
                        <tr>
                            <td colSpan={9}>
                               <p className='instruction-invoice2'>For VAREPRINT PRIVATE LIMITED
                               <br /> <img src={`${baseURL}/images/sign.jpeg`} className='sign-invoice' />
                               <br />(Authorised Signatory)</p> 
                            </td>
                            
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Invoice;
