import React from 'react'
import { Link } from 'react-router-dom';

function addvertise() {
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  return (
    <React.Fragment>
      <div className='container-fluid'>
      <hr className="split1" />
        <div className='row'>
            {/* <div className='col-sm-6'>
            <video width="100%" height="auto" controls autoPlay muted>
            <source src="http://localhost:3008/video/sequence.mp4" type="video/mp4" />
          </video> */}
            {/* </div> */}
            <div className='col-sm-12'>
                <img src={`${baseURL}/images/Banner-01.webp`} className='add_image' />
                <Link to={`/preview/50`}>
                <button className='shop-now-1'>Show Now</button>
                
                </Link>
                <Link to={`/preview/26`}>
                <button className='shop-now-2'>Show Now</button>
                </Link>
                </div>
        </div>
        <hr className="split2" />
        </div>

    </React.Fragment>
      
  
  )
}

export default addvertise
