import React from 'react';
import Nav from './nav';
import Footer from './footer';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const contact_us = () => {



  return (
    <React.Fragment>
      <Nav />
      <div className='container-fluid'>
        <div className='row sub_contact_banner'>
            <div className='col-sm-12'>
            <img src={`${baseURL}/images/contactusbanner7.jpeg`} alt='cotact_banner' className='contact_banner' />
            </div>
            
            <div className='card card_details'>
                <h2>We'd love to hear from you.
                </h2>
               <div className='col-sm-12'>
                <label>*Name</label>
                <input type='text' className='form-control' />
                <label>*Contact</label>
                <input type='text' className='form-control' />
                <label>*Email</label>
                <input type='text' className='form-control' />
                <label>*Subject</label>
                <input type='text' className='form-control' />
                <label>*Message</label>
               <textarea className='form-control'></textarea>

               <button>Submit</button>
               </div>
            </div>
            
        </div>
      </div>
      
      <Footer />
    </React.Fragment>
  );
};

export default contact_us;
