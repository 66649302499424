import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'js-cookie';
import Nav from './nav';
import Top from './top';
import Footer from './footer';
import { Link, useNavigate } from 'react-router-dom';
import DeleteLink from './deletelink';
import './cart.css';
import { useUser } from './user'; // Adjust the import path as needed
const baseURL = process.env.REACT_APP_API_BASE_URL;




const Cart = () => {
  const { userId, cookieUser, sessionUser } = useUser();
  const navigate = useNavigate();
  const [carts, setCarts] = useState([]);
  const [cartvalue, setCartValue] = useState({
    total_withoutsgst: 0, total_gstprice: 0, total: 0 
  });
  const [designData, setDesignData] = useState([]);
  const [cookieValue, setCookieValue] = useState('');
  const [confirmOrder, setconfirmOrder] = useState([]);
  const [notification, setNotification] = useState('');
  const [showImageModal, setShowImageModal] = useState(false); 
  const [selectedImage, setSelectedImage] = useState(''); 

  useEffect(() => {
  
    if (userId) {      // Fetch cart details based on sessID
      axios.get(`${baseURL}/cart/${userId}`)
        .then(response => {
          const cartdata = response.data.cartdetails || [];
        
     
          setconfirmOrder(cartdata);
          setCarts(cartdata); // Set the cart data array
          setCartValue({
            total_withoutsgst: response.data.total_withoutsgst.toFixed(2),
            total_gstprice: response.data.total_gstprice.toFixed(2),
            total: response.data.total.toFixed(2),
          });
          const designIds = [...new Set(cartdata.map(cart => cart.did))];
          // Fetch design data for each unique design ID
          fetchDesignData(designIds);
        })
        .catch(error => {
          console.error('Error fetching cart details:', error);
        });
      }
  }, [userId]);

  // Function to fetch design data for each design ID
  const fetchDesignData = (designIds) => {
    designIds.forEach(did => {
      axios.get(`${baseURL}/design-fetch/${did}`)
        .then(response => {
          const designdata = response.data.designdetails || [];
          setDesignData(prevData => [
            ...prevData,
            ...designdata.map(design => ({
              ...design,
              id: did // Attach the cart ID to each design object
            }))
          ]);
        })
        .catch(error => {
          console.error(`Error fetching design details for design ID ${did}:`, error);
        });
    });
  };

 



  const fetchCartDetails = () => {
    // Fetch cart details based on user ID or session
    axios.get(`${baseURL}/cart/${userId}`)
      .then(response => {
        const cartdata = response.data.cartdetails || [];
       
        setconfirmOrder(cartdata)
        setCarts(cartdata); // Set the cart data array
        setCartValue({
          total_withoutsgst: response.data.total_withoutsgst.toFixed(2),
          total_gstprice: response.data.total_gstprice.toFixed(2),
          total: response.data.total.toFixed(2),
        });
      })
      .catch(error => {
        console.error('Error fetching cart details:', error);
      });
  };


   // Function to handle deletion of an item from the cart
   const handleDelete = (cart_id) => {
   

    axios
      .delete(`${baseURL}/cart/delete/${cart_id}`)
      .then((response) => {
        if (response.status === 200) {
          // Filter out the deleted cart item from state
          setCarts((prevCarts) => prevCarts.filter((cart) => cart.cart_id !== cart_id));
          showNotification('Successfully deleted'); // Show success notification
          fetchCartDetails(); 
        }
      })
      .catch((error) => {
        console.error('Error deleting item from cart:', error);
        showNotification('Failed to delete item'); // Show failure notification
      });
  };


  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => {
      setNotification('');
    }, 3000);
  };

  const handleProceedToCheckout = () => {
    axios.post(`${baseURL}/confirm_order`,  {confirmOrder })
      .then(response => {
        if (response.status === 200) {
          if (sessionUser) {
            navigate('/checkout');
          } else if (cookieUser) {
            navigate('/login-page');
          }
        }
      })
      .catch(error => {
        console.error('Error confirming order:', error);
        showNotification('Failed to proceed to checkout');
      });
  };

  const handleImageClick = (imagePath) => {
   
  
    setSelectedImage(imagePath);
    setShowImageModal(true);
  };
  const handleCloseImageModal = () => {
    setShowImageModal(false);
    setSelectedImage('');
  };

  return (
    <React.Fragment>
      <Nav />
      <Top />
      <div className='container'>
        <ul className='filter_ul'>
          <Link to={`/`}>
            <li>Home</li>
          </Link>
          <li>&nbsp;/&nbsp;</li>
          <li>Cart</li>
        </ul>
      </div>
      <div className='container cart-container2'>
        <div className='row cart-row1'>
          <div className='col-sm-8'>
            <div className='row  cart-row2'>
              <div className='col-sm-8 your_Cart_div'>YOUR CART</div>
              <div className='col-sm-4 continue_shopping_div'>
                <Link to={`/`}>
                <button className='continue_shopping'>CONTINUE SHOPPING</button>
                </Link>
              </div>
            </div>
            <hr />
            <div className='row cart-row3'>
             
              <div className='col-sm-8'>PRODUCT DESCRIPTION</div>
              <div className='col-sm-4 price_title_cart'>PRICE</div>
            
            </div>
            <hr />
            {notification && <div className="alert alert-success">{notification}</div>}
            {/* Render cart details */}
            {carts.map((cart, index) => (
              <div key={index}>
                {designData
                  .filter(design => design.id === cart.did) // Filter design data by cart.did
                  .map((design, idx) => (
                    <div className='row cart-row4' key={idx}>
                      <div className='col-sm-3'>
                      {cart.imagename1 ? (
  <img 
    src={cart.imagename1} 
    alt={design.pname} 
    className="cart_image"
    onClick={() => handleImageClick(`${cart.imagename1}`)}
  />
) : cart.color ? (
  cart.color === 'black' ? (
    <img 
      src={`${baseURL}/images/design/${design.blackImage}`} 
      alt={design.pname} 
      className="cart_image"
      onClick={() => handleImageClick(`${baseURL}/images/design/${design.blackImage}`)}
    />
  ) : cart.color === 'white' ? (
    <img 
      src={`${baseURL}/images/design/${design.whiteImage}`} 
      alt={design.pname} 
      className="cart_image"
      onClick={() => handleImageClick(`${baseURL}/images/design/${design.whiteImage}`)}
    />
  ) : (
    // Fallback for colors other than black or white
    <img 
      src={`${baseURL}/images/design/${design.images}`} 
      alt={design.pname} 
      className="cart_image"
      onClick={() => handleImageClick(`${baseURL}/images/design/${design.images}`)}
    />
  )
) : (
  // Fallback if neither imagename1 nor color is available
  <img 
    src={`${baseURL}/images/design/${design.images}`} 
    alt={design.pname} 
    className="cart_image"
    onClick={() => handleImageClick(`${baseURL}/images/design/${design.images}`)}
  />
)}

                       
                      </div>
                      <div className='col-sm-5'>
                        <p className='p_cart_name'>{design.pname}</p>
                        {/* <p>{design.ds1}</p> */}
                        <p  className='cart-quantity card-data-desc'>Quantity: {cart.quantity}</p>
                       {cart.color && (
  <p className='cart-quantity card-data-desc'>Color: {cart.color}</p>
)}
   {cart.size && (
  <p className='cart-quantity card-data-desc'>Size: {cart.size}</p>
)}
                      </div>
                      <div className='col-sm-2'>
                        <p className='p_cart_rate'>&#8377;{cart.total}</p>
                      </div>
                      <div className='col-sm-2'>
                      <button className="btn" onClick={() => handleDelete(cart.cart_id)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
</svg>

</button>

                      </div>
                    </div>
                  ))}
                <hr />
              </div>
            ))}
          </div>
          <div className='col-sm-4'>
            <div className='summary_div'>
              <div className='row'>
                <div className='col-sm-12'>
                  <p className='order_summary_title'>ORDER SUMMARY</p>
                </div>
              </div>
              <hr />
              <div className='row total_cart_div'>
                <div className='col-sm-8'>
                  <p className=''>SUBTOTAL</p>
                </div>
                <div className='col-sm-4 '>
                  <p className='rate-cart'>&#8377;{cartvalue.total_withoutsgst}</p>
                </div>
                </div>
                <div className='row total_cart_div'>
                <div className='col-sm-8'>
                  <p className=''>Tax</p>
                </div>
                <div className='col-sm-4'>
                  <p className='rate-cart'>&#8377;{cartvalue.total_gstprice}</p>
                </div>
              </div>
              <hr />
              <div className='row total_cart_div'>
                <div className='col-sm-8'>
                  <p className=''>Total</p>
                </div>
                <div className='col-sm-4'>
                  <p className='rate-cart'>&#8377;{cartvalue.total}</p>
                </div>
              </div>
            </div>
            <div className='row checkout_row2'>
              <button className='continue_shopping' onClick={handleProceedToCheckout}>PROCEED TO CHECKOUT</button>
            </div>
          </div>
        </div>
      </div>
      <br />

        {/* Image Modal */}
        <Modal show={showImageModal} onHide={handleCloseImageModal}>
        {/* <Modal.Header closeButton>
        
        </Modal.Header> */}
        <Modal.Body>
          {selectedImage ? <img src={selectedImage} alt="Design" className="img-fluid" /> : 'No Image'}
        </Modal.Body>
       
      </Modal>
      <Footer />
    </React.Fragment>
  );
};

export default Cart;
