import React from 'react'
import bannerImage from '../images/banner/banner2.webp';
function banner() {
  return (
    <div>
       <img src={bannerImage} className="banner_imgage" alt="Banner Image" />
    </div>
  )
}

export default banner
