import React, {  useState }  from "react";
import { Link } from "react-router-dom";


const Login = () => {
    const [email, setEmail] = useState();
    const handleInputChange = (e) => {
        setEmail(e.target.value)
    }
    return (
        <>


        <div className="container rating-container">
            <div className="card rating-card">
            <p className="rating-heading">Login</p>
            <p className="rating-desc">Share your experience about our prodcuts and our Services. </p>
        
          
            <input type="text" className="form-control rating-input-login" placeholder="Enter your name or email"  onChange={handleInputChange} name="username"/>
          
            <Link to={`/rating/${email}`}>
            <button className="btn btn-primary rating-login-button">LOGIN INTO PORTAL</button>
            </Link>  
            </div>
        </div>
        {/* <div className="card">
            <div className="login">
                <h4>Login</h4>
                <label>Email</label>
                <input type="text" onChange={handleInputChange} className="form-control" name="email" />
            
        <Link to={`/rating/${email}`}><button  type="button" data-mdb-button-init data-mdb-ripple-init class="btn btn-primary btn-block mb-4">Sign in</button></Link>  
        </div>
            </div> */}
        </>
    )
}

export default Login;